
export default {
    BASE_URL: "https://tma.pixeln.cn/",        // 基本地址
    login: "api/msy/login",                     // ⽤户登录
    sendCode: "api/msy/sendCode",               // 发送验证码
    getTokenInfo: "api/msy/getTokenInfo",       // 获取token详细信息
    getTalkTokenInfo: "api/msy/getTalkTokenInfo",   // 获取聊天token详细信息
    getUserInfo: "api/msy/getUserInfo",         // 获取用户的详细信息
    getChoiceTokens: "api/msy/getChoiceTokens", // 获取可选的token信息
    setChoiceToken: "api/msy/setChoiceToken",   // 设置选择的token
    getUserShareInfo: "api/msy/getUserShareInfo",     // 获取用户的分享信息
    getShareInTokenInfo: "api/msy/getShareInTokenInfo", // 获取分享页的用户相关信息
    getDesignerTokenInfo: "api/msy/getDesignerTokenInfo", // 获取创世马的相关信息
    setShareLogs: "api/msy/setShareLogs",       // 设置分享记录
    chat: "api/msy/chat",                       // 聊天(GPT3)
    chatTurbo: "api/msy/chatTurbo",             // 聊天(chatGPT)
    getMBInfo: "api/msy/getMBInfo",             // 获得MB信息
    feedToken: "api/msy/feedToken",             // 喂马
}
