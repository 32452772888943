<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" left-arrow @click-left="onClickLeft" />
  </div>
  <div style="margin-top: 40px">
    <van-image width="300px" height="300px" fit="cover" :src="tokenThumb" />
  </div>
  <div style="margin-top: 10px">
  <div style="padding-left: 30px; text-align: left;">
    <a class="download" :href="tokenThumb">下载300px版本 (头像用)</a>
  </div>
  <div style="padding-left: 30px; text-align: left;">
    <a class="download" :href="tokenImage">下载3000px版本 (印刷用)</a>
  </div>
  </div>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";

export default {
  setup() {
    const router = useRouter();
    const userId = window.sessionStorage.getItem("userId");
    const nickname = window.sessionStorage.getItem("nickname");
    const tokenId = window.sessionStorage.getItem("tokenId");
    const image = require('../assets/mashowu.png');
    const tokenThumb = ref(image);
    const tokenImage = ref("");

    const titleStr = ref(nickname + "，#" + tokenId);

    onMounted(async () => {
      let result = await postRequest(apiUrl.getTokenInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenThumb.value = "https://" + data.thumburl;
        tokenImage.value = "https://" + data.imageurl;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    })

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const onClickLeft = () => history.back();

    return {
      titleStr,
      tokenThumb,
      tokenImage,
      onClickLeft
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

.van-action-bar {
  background-color: transparent;
}

.van-button--large {
  height: 45px;
}

.download {
  width: 135px;
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  color: black;
  line-height: 40px;
}

#footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.14px;
}
</style>