<template>
  <div style="margin-top: 20px; height: 33px; margin-bottom: 30px;">
    <p>领养成功</p>
  </div>
  <div style="margin: 15px">
    <van-image width="350px" height="350px" fit="cover" :src="tokenImage" />
  </div>
  <div style="margin: 40px">
    <h3>编号：#{{ tokenID }}</h3>
    <h3>稀有度：{{ tokenRarity }}</h3>
  </div>
  <div style="margin: 15px">
    <van-form @submit="onSubmit">
      <van-button block type="primary" size="large" color="#3fa2f7" native-type="submit">
        和我的马聊聊
      </van-button>
    </van-form>
  </div>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";

export default {
  setup() {
    const router = useRouter();
    const userId = window.sessionStorage.getItem("userId");
    const tokenId = window.sessionStorage.getItem("tokenId");
    const image = require('../assets/mashowu.png');
    const tokenImage = ref(image);
    const tokenID = ref(tokenId);
    const tokenRarity = ref("0");

    const onSubmit = () => {
      console.log("onSubmit");
      router.push({ name: "home" });
    };

    onMounted(async () => {
      let result = await postRequest(apiUrl.getTokenInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenImage.value = "https://" + data.thumburl;
        tokenRarity.value = data.rarity;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    })

    return {
      tokenImage,
      tokenID,
      tokenRarity,
      onSubmit,
    };
  },
};
</script>

<style scoped>
p {
  padding: 20px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 600;
  text-align: center;
  color: #000000;
  letter-spacing: -0.14px;
}

h3 {
  height: 15px;
  font-size: 15px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 7px;
  letter-spacing: -0.14px;
}
</style>
