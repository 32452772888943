<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" left-arrow @click-left="onClickLeft" />
  </div>
  <van-row>
    <van-col span="8"><img :src="tokenImage" class="thumbimg" style="object-fit: cover;" /></van-col>
    <van-col span="16" style="margin-top: 30px;">
      <van-row justify="space-around" style="color:black;font-size:16px;">
        <van-col span="8">
          <span>{{ tokenShare }}</span>
        </van-col>
        <van-col span="8">
          <span>{{ tokenShareIn }}</span>
        </van-col>
        <van-col span="8">
          <span>{{ tokenAdopts }}</span>
        </van-col>
      </van-row>
      <van-row justify="space-around" style="color:black;font-size:16px;">
        <van-col span="8"><span>分享</span></van-col>
        <van-col span="8"><span>浏览</span></van-col>
        <van-col span="8"><span>领养</span></van-col>
      </van-row>
    </van-col>
  </van-row>
  <van-row justify="space-around" style="margin:10px">
    <van-col span="10">
      <van-button round type="primary" size="large" color="#3fa2f7" style="margin-top:5px" to="/toshare">
        分享
      </van-button>
    </van-col>
    <van-col span="10">
      <van-button round type="primary" size="large" color="#3fa2f7" style="margin-top:5px" to="/download">
        下载
      </van-button>
    </van-col>
  </van-row>
  <div style="margin: 20px">
    <p>稀有度：{{ tokenRarity }}</p>

    <p>
      背景：{{ tokenBackground }}<br />
      皮肤：{{ tokenSkin }}<br />
      纹身：{{ tokenTattoo }}<br />
      服装：{{ tokenDress }}<br />
      眼睛：{{ tokenEye }}<br />
      特定：{{ tokenConflict }}<br />
      手持物：{{ tokenHandAccessories }}<br />
      颈部配饰：{{ tokenNeckAccessories }}<br />
      鼻子配饰：{{ tokenNoseAccessories }}<br />
      耳朵配饰：{{ tokenEarAccessories }}<br />
      头部配饰：{{ tokenHeadAccessories }}<br />
      嘴巴配饰：{{ tokenMouthAccessories }}<br />
      眼睛配饰：{{ tokenEyeAccessories }}
    </p>
    <p>
      链：BSN 武汉链<br />
      发行方：元创像素<br />
      合约地址：{{ tokenContract }}<br />
      交易哈希：<br />{{ tokenHash }}<br />
    </p>
  </div>
  <div style="margin: 20px">
    <a href="https://www.yuque.com/books/share/bdbe6f7a-7571-4fef-895b-2b061283f618"><span style="text-align: left;">阅读《「马上友」项目白皮书》
    </span></a>
    <h3>马上关注「⻢上友」社交媒体号</h3>
  </div>
  <div style="margin-bottom: 50px;">
    <img style="width: 150px; height:180px" src="../assets/weibo.jpg" />
    <img style="width: 150px; height:150px" src="../assets/wechat.jpg" />
  </div>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";

export default {
  setup() {
    const router = useRouter();
    const nickname = window.sessionStorage.getItem("nickname");
    const tokenId = window.sessionStorage.getItem("tokenId");
    const userId = window.sessionStorage.getItem("userId");

    const titleStr = ref(nickname + "，#" + tokenId);
    const image = require('../assets/mashowu.png');
    const tokenImage = ref(image);
    const tokenRarity = ref(0);
    const tokenBackground = ref("A000");
    const tokenSkin = ref("B000");
    const tokenTattoo = ref("C000");
    const tokenDress = ref("D000");
    const tokenEye = ref("E000");
    const tokenConflict = ref("F000");
    const tokenHandAccessories = ref("G000");
    const tokenNeckAccessories = ref("H000");
    const tokenNoseAccessories = ref("I000");
    const tokenEarAccessories = ref("J000");
    const tokenHeadAccessories = ref("K000");
    const tokenMouthAccessories = ref("L000");
    const tokenEyeAccessories = ref("M000");
    const tokenHash = ref("0x");
    const tokenContract = ref("0x");
    const tokenShare = ref(0);
    const tokenShareIn = ref(0);
    const tokenAdopts = ref(0);

    const getTokenInfo = async() => {
      let result = await postRequest(apiUrl.getTokenInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenImage.value = "https://" + data.thumburl;
        tokenRarity.value = data.rarity;
        tokenBackground.value = data.background;
        tokenSkin.value = data.skin;
        tokenTattoo.value = data.tattoo;
        tokenDress.value = data.dress;
        tokenEye.value = data.eye;
        tokenConflict.value = data.conflict;
        tokenHandAccessories.value = data.handa;
        tokenNeckAccessories.value = data.necka;
        tokenNoseAccessories.value = data.nosea;
        tokenEarAccessories.value = data.eara;
        tokenHeadAccessories.value = data.heada;
        tokenMouthAccessories.value = data.moutha;
        tokenEyeAccessories.value = data.eyea;
        tokenHash.value = data.hash;
        tokenContract.value = data.contract;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    }

    const getShareInfo = async() => {
      let result = await postRequest(apiUrl.getUserShareInfo, {
        userId: userId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        // tokenThumb.value = getImageUrl(data.userTokenInfo.thumburl);
        tokenShare.value = data.userTokenInfo.shares;
        tokenShareIn.value = data.userTokenInfo.shareins;
        tokenAdopts.value = data.userTokenInfo.adopts;
        // adoptsTokenInfo.value = data.userAdoptsTokenInfo;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    }

    onMounted(async () => {
      await getTokenInfo();
      await getShareInfo();
    })

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const onClickLeft = () => history.back();

    return {
      titleStr,
      tokenId,
      tokenImage,
      tokenRarity,
      tokenBackground,
      tokenSkin,
      tokenTattoo,
      tokenDress,
      tokenEye,
      tokenConflict,
      tokenHandAccessories,
      tokenNeckAccessories,
      tokenNoseAccessories,
      tokenEarAccessories,
      tokenHeadAccessories,
      tokenMouthAccessories,
      tokenEyeAccessories,
      tokenHash,
      tokenContract,
      onClickLeft,
      tokenShare,
      tokenShareIn,
      tokenAdopts
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

.van-action-bar {
  background-color: transparent;
}

.van-button--large {
  height: 45px;
}

.thumbimg {
  margin: 10px;
  width: 110px;
  height: 110px;
}

.thumbimg_follow {
  width: 88px;
  height: 88px;
}

p {
  padding-left: 15px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: left;
  color: #000000;
  letter-spacing: -0.08px;
  //text-overflow:ellipsis;
  //overflow:hidden;
  word-wrap: break-word;
}

a {
  width: 252px;
  height: 25px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  color: #000000;
  line-height: 25px;
}

h3 {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  color: #000000;
}

#footer {
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.14px;
}
</style>