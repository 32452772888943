import router from '@/router';
import axios from 'axios';
import apiUrl from "../utils/api.url";

// 创建axios实例
const service = axios.create({
    baseURL: apiUrl.BASE_URL,
    timeout: 10000
})

// request拦截器
service.interceptors.request.use((config) => {
    // 获得token，并赋值
    let token = window.sessionStorage.getItem("token");
    // console.log('token', token);
    if (token) {
        config.headers.Authorization = token;
    }
    console.log('request:', config);
    return config;
}, (error) => {
    return Promise.reject(error);
})

// response拦截器
service.interceptors.response.use((response) => {
    console.log('response:', response);
    return response;
}, (error) => {
    console.log('response[ERROR]:', response);
    if (error.response && error.response.status === 401) {
        window.sessionStorage.setItem("token", "");
        window.sessionStorage.setItem("userId", "");
        window.sessionStorage.setItem("tokenId", "");
        window.sessionStorage.setItem("nickname", "");
        router.replace({
            path: '/login',
            query: {
                redirect: router.currentRoute.value.fullPath
            }
        })
    }
    return Promise.reject(error);
})

const postRequest = (api, params = {}) => {
    return new Promise((resolve, reject) => {
        service.post(api, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                resolve(response)
            }, (err) => {
                reject(err.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export {
    postRequest
}