<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" />
  </div>
  <div style="margin-top: 40px">
    <van-image width="300px" height="300px" fit="cover" :src="tokenImage" />
  </div>
  <div style="margin: 20px">
    <p>每匹马的背后是AI聊天机器人，你可以通过投喂MB和马进行聊天，每次聊天须投喂2MB。</p>
  </div>
  <van-row justify="space-around" style="margin:20px">
    <van-col span="14">
      <p>你的MB数量：{{ mbBalance }}</p>
    </van-col>
    <van-col span="10" @click="toEarnMB">
      <p style="font-weight: bold;text-decoration: underline;">赚取更多MB</p>
    </van-col>
  </van-row>
  <van-action-bar style="margin:35px">
    <van-action-bar-button color="#3FA2F7" type="primary" icon="chat" to="/chatlist" />
    <van-action-bar-button color="#3FA2F7" type="primary" icon="manager" to="/my" />
  </van-action-bar>
  <div id="footer">元创像素 ©2023 版权所有</div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";

export default {
  setup() {
    const router = useRouter();
    const nickname = window.sessionStorage.getItem("nickname");
    const tokenId = window.sessionStorage.getItem("tokenId");
    const userId = window.sessionStorage.getItem("userId");

    const titleStr = ref(nickname + "，#" + tokenId);
    const image = require('../assets/mashowu.png');
    const tokenImage = ref(image);
    const mbBalance = ref(0);

    const getTokenInfo = async () => {
      try {
        let result = await postRequest(apiUrl.getTokenInfo, {
          userId: userId,
          tokenId: tokenId
        });
        // 处理返回结果
        if (result.data.code == 200) {
          const data = result.data.data;
          // console.log(result.data);
          tokenImage.value = "https://" + data.thumburl;
        } else {
          // 打印出错信息
          console.log(result.data.msg);
          // 跳转到login，重新走login流程
          Dialog.alert({
            title: "马上友",
            message: result.data.msg,
          }).then(() => {
            // to Login
            router.push({ name: "login" });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    const getMBInfo = async () => {
      try {
        let result = await postRequest(apiUrl.getMBInfo, {
          userId: userId,
          tokenId: tokenId
        });
        // 处理返回结果
        if (result.data.code == 200) {
          const data = result.data.data;
          // console.log(result.data);
          mbBalance.value =  data.mb;
        } else {
          // 打印出错信息
          console.log(result.data.msg);
          // 跳转到login，重新走login流程
          Dialog.alert({
            title: "马上友",
            message: result.data.msg,
          }).then(() => {
            // to Login
            router.push({ name: "login" });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      await getTokenInfo();
      await getMBInfo();
    })

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const toEarnMB = (event) => {
      router.push({ "name": "earnmb" });
    }

    return {
      titleStr,
      tokenId,
      tokenImage,
      mbBalance,
      toEarnMB,
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

.van-action-bar {
  background-color: transparent;
}

.van-button--large {
  height: 45px;
}

p {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: justify;
  color: #297fca;
  letter-spacing: -0.08px;
  word-wrap: break-word;
}

a {
  width: 252px;
  height: 25px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  color: #297fca;
  line-height: 25px;
}

h3 {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: center;
  color: #000000;
}
</style>