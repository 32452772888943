<template>
  <div style="margin-top: 30px; margin-bottom: 10px">
    <van-image width="300" height="300" fit="cover" :src="require('../assets/mashowu.png')" />
  </div>
  <div>
    <p>
      「马上友」是一个全民参与，共同创造流行文化的社区。我们一同分享社区增长带来的利益，一起参与社区的治理活动，达致共识、共创、共享、共治的状态。
    </p>
  </div>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field v-model="phone" name="phone" autosize placeholder="手机号" :autofocus=true
          :rules="[{ required: true, message: '手机号不能为空' }]" />
        <van-field v-model="code" name="code" clearable autosize placeholder="验证码" tyep="digit" maxlength="6"
          :rules="[{ required: true, message: '验证码不能为空' }]">
          <template v-if="codeSend" #button>
            <van-button size="small" round type="primary" color="#297fca" plain disabled style="border: none"><span
                style="font-size: 15px">重发发送({{
                  codeText
                }})</span></van-button>
          </template>
          <template v-else #button>
            <van-button size="small" round type="primary" color="#297fca" plain style="border: none" @click="onSendSMS">
              <span style="font-size: 15px">发送验证码</span></van-button>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 15px">
        <van-button block type="primary" size="large" color="#3fa2f7" native-type="submit">
          登录
        </van-button>
      </div>
    </van-form>
  </div>
  <div>
    <h3>若未注册手机则验证后自动完成注册</h3>
  </div>
  <div id="footer">
    <span>粤ICP备2022052367号-1</span>
    粤网信备4401032292310104001X号
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "../utils/api.url";
import { Dialog, Toast } from "vant";
import "vant/es/toast/style";
import "vant/es/dialog/style";

export default {
  setup() {
    const router = useRouter();

    // 进入登录页面，先清理干净sessionStorage
    window.sessionStorage.setItem("userId", "");
    window.sessionStorage.setItem("nickname", "");
    window.sessionStorage.setItem("token", "");
    window.sessionStorage.setItem("tokenId", "");

    const phone = ref("");
    const code = ref("");
    let codeSend = ref(false);
    let codeText = ref(60);
    let codeSetTimeout = "";

    const redirect = router.currentRoute._value.query.redirect;
    // console.log(redirect);

    // 从redirect中获取相关信息，仅支持一个参数
    let getInfo = (path) => {
      let data;

      data = {
        "path": path.substring(1, path.indexOf("?")),
        "pname": path.substring(path.indexOf("?") + 1, path.indexOf("=")),
        "pvalue": path.substring(path.indexOf("=") + 1, path.length)
      }
      return data;
    }


    // 判断手机号码是否合法
    let checkPhoneNum = (phone) => {
      // console.log(phone);
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        // console.log( "手机号格式错误" );
        return false;
      } else {
        // console.log( "手机号码格式正确" );
        return true;
      }
    };

    // 发送短信验证码
    const onSendSMS = async () => {
      // 验证手机号码合法性
      if (!checkPhoneNum(phone.value)) {
        Toast.fail("手机号码输入错误");
        return;
      }
      let result = await postRequest(apiUrl.sendCode, {
        phoneNum: phone.value,
      });
      if (result.data.code == 200) {
        // 设置倒计时状态
        codeSend.value = true;
        codeTimeoutIng();
      } else {
        Toast.fail(result.data.msg);
      }
    };

    const codeTimeoutIng = () => {
      if (codeText.value <= 1) {
        // 倒数结束，重置状态和初始倒数指，并返回
        codeSend.value = false;
        codeText.value = 60;
        return;
      }
      codeSetTimeout = setTimeout(() => {
        codeText.value--;
        codeTimeoutIng();
      }, 1000);
    };

    // 提交进行账号登录验证
    const onSubmit = async (values) => {
      // console.log("submit", values);
      let result = await postRequest(apiUrl.login, {
        phoneNum: values.phone,
        code: values.code,
      });

      // 处理返回结果
      if (result.data.code == 200) {
        // 先清楚在跑的定时器
        clearTimeout(codeSetTimeout);
        // 保存token、userId和nickName
        window.sessionStorage.setItem("token", result.data.data.token);
        window.sessionStorage.setItem("userId", result.data.data.userId);
        window.sessionStorage.setItem("nickname", result.data.data.nickname);
        // 判断是否存在tokenId
        if (result.data.data.msyTokenId) {
          window.sessionStorage.setItem("tokenId", result.data.data.msyTokenId);
        } else {
          window.sessionStorage.setItem("tokenId", "");
        }
        // 跳转到下一页
        if (redirect == {} || redirect == undefined || redirect == "/") {
          router.push({ name: "home" });
        }
        else {
          //console.log(redirect);
          let nextInfo = getInfo(redirect);
          console.log(nextInfo);
          router.push({ path: nextInfo.path, query: { [nextInfo.pname]: nextInfo.pvalue } });
        }
        console.log("Login Successed.");
      } else {
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          //on close
        });
        console.log("Login Error:", result.data.msg);
      }
    };

    return {
      phone,
      code,
      codeSend,
      codeText,
      onSubmit,
      onSendSMS,
    };
  },
};
</script>

<style scoped>
p {
  padding: 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: justify;
  color: #000000;
  letter-spacing: -0.08px;
}

h3 {
  height: 20px;
  font-size: 15px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #000000;
  line-height: 8px;
  letter-spacing: -0.14px;
}
</style>